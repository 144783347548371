import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import App from './App.vue'

const app = createApp(App)

Sentry.init({ app,
    dsn: "https://254eb2c46c8d87d2e4b7805ad83523a7@o4507695426961408.ingest.us.sentry.io/4507811592011776",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['https://api.staging.infinitegiving.com', 'https://api.infinitegiving.com'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
})

app.mount('#app')
